import { sprintf } from 'sprintf-js';

import { Icon } from 'components/Icon/Icon';
import { IdealistLogoOnDemand } from 'components/Logo/IdealistLogoOnDemand';
import { pageFooterDataByLocale } from 'mappings/PageFooter/pageFooterDataByLocale';
import { homeRoute } from 'routing/routes';
import { trackAnchorClicked } from 'utils/analytics/track/trackAnchorClicked';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { PageFooterLanguageLinks } from './LanguageLinks/PageFooterLanguageLinks';
import {
  PageFooterBottom,
  PageFooterCopyright,
  PageFooterHolder,
  PageFooterLogoLink,
  PageFooterLogoMissionHolder,
  PageFooterMissionStatement,
  PageFooterSectionHolder,
  PageFooterSectionLink,
  PageFooterSectionLinksHolder,
  PageFooterSectionTitle,
  PageFooterSectionsAndLogoMissionHolder,
  PageFooterSectionsHolder,
  PageFooterSocialLink,
  PageFooterSocialLinksHolder,
} from './PageFooter.styled';
import {
  pageFooterDataByLinkType,
  pageFooterSocialLinks,
} from './pageFooterConstants';
import { pageFooterOrderedSectionsMetadata } from './pageFooterOrderedSectionsMetadata';

type Props = {
  locale: UserLocale;
  urlByLocale: Record<UserLocale, string | undefined> | undefined;
  showDivider: boolean;
};

export function PageFooter({ locale, urlByLocale, showDivider }: Props) {
  const data = pageFooterDataByLocale[locale];

  return (
    <PageFooterHolder data-qa-id="footer" $showDivider={showDivider}>
      <PageFooterSectionsAndLogoMissionHolder $showDivider={showDivider}>
        <PageFooterSectionsHolder>
          {pageFooterOrderedSectionsMetadata.map(
            ({ sectionName, desktopOnly }) => {
              const section = data[sectionName];

              return (
                <PageFooterSectionHolder
                  key={sectionName}
                  $desktopOnly={Boolean(desktopOnly)}
                >
                  <PageFooterSectionTitle>
                    {section.title}
                  </PageFooterSectionTitle>

                  <PageFooterSectionLinksHolder>
                    {section.links.map((link) => {
                      const { trackClickedName } =
                        pageFooterDataByLinkType[link.type];

                      return (
                        <PageFooterSectionLink
                          key={link.title}
                          to={link.href}
                          onClick={() => {
                            trackClicked(trackClickedName, {
                              context: 'Footer',
                            });
                          }}
                        >
                          {link.title}
                        </PageFooterSectionLink>
                      );
                    })}
                  </PageFooterSectionLinksHolder>
                </PageFooterSectionHolder>
              );
            },
          )}
        </PageFooterSectionsHolder>

        <PageFooterLogoMissionHolder $showDivider={showDivider}>
          <PageFooterLogoLink
            to={homeRoute.with({ lang: CURRENT_LOCALE })}
            onClick={() => trackClicked('Footer Logo')}
          >
            <IdealistLogoOnDemand withText height={56} />
          </PageFooterLogoLink>

          <PageFooterMissionStatement>
            {
              /* Mission statement under logo */
              getText(
                `Working with others, in a spirit of generosity and mutual respect, we want to help build a world where all people can lead free and dignified lives.`,
              )
            }
          </PageFooterMissionStatement>

          <PageFooterSocialLinksHolder>
            {pageFooterSocialLinks.map(({ href, eventName, iconName }) => (
              <PageFooterSocialLink
                href={href}
                key={iconName}
                onClick={(event) => trackAnchorClicked(event, eventName)}
                aria-label={eventName}
              >
                <Icon name={iconName} size={20} colorName="white" />
              </PageFooterSocialLink>
            ))}
          </PageFooterSocialLinksHolder>
        </PageFooterLogoMissionHolder>
      </PageFooterSectionsAndLogoMissionHolder>

      <PageFooterBottom>
        <PageFooterLanguageLinks urlByLocale={urlByLocale} />

        <PageFooterCopyright>
          {sprintf(getText('Copyright © %(year)s Idealist.org'), {
            year: new Date().getFullYear(),
          })}
        </PageFooterCopyright>
      </PageFooterBottom>
    </PageFooterHolder>
  );
}
